import mLib from './js/mapbox/mapbox';
import { pictograms } from './js/pictograms';
import { coordinatesAreEqual } from './js/tools';

import './css/map.scss';

// ==================================================================================================
// Documentation
// ==================================================================================================

/* markersLists = [{
    type: string,
    markers: [{
        center: { lng: number, lat: number },
        type: string,
        bbox?: [{ lng: number, lat: number }],
        custom?: {
            isPin?: string,
            pinColor?: string,
            icon?: string
        }
    }]
}] */

/* cameraPosition = {
    center: number,
    zoom: number,
    pitch: number,
    bearing: number
} */

/* mapOptions = {
    interactive: boolean,
    editable: boolean,
    geocoder: boolean,
    printable: boolean,
    lists: {
        [type: string]: {
            link: boolean,
            label: string,
            markers: [{
                [type: string]: {
                    isPin?: boolean,
                    pinColor?: string,
                    label: string,
                    icon: string,
                    clusterPriority?: number,
                    default?: boolean,
                    first?: boolean,
                    last?: boolean
                }
            }]
        }
    }
} */

// ==================================================================================================
// Load & Display Map
// ==================================================================================================

function defaultMapOptions(
  containerId,
  {
    interactive = false,
    editable = false,
    geocoder = false,
    printable = false,
    ...otherOptions
  }
) {
  if (!interactive) {
    editable = false;
  }
  if (!editable) {
    geocoder = false;
  }
  const scroll = {
    displayMsg: () =>
      $(`#${containerId} .tp-table-map-scroll`).removeClass('hidden'),
    hideMsg: () => $(`#${containerId} .tp-table-map-scroll`).addClass('hidden'),
  };
  const clusters = getClustersPriority(otherOptions.lists);

  return {
    interactive,
    editable,
    geocoder,
    printable,
    scroll,
    pictograms,
    clusters,
    ...otherOptions,
  };
}

function defaultMarkersLists(markersLists = [], mapOptions) {
  if (markersLists.length === 0) {
    return Object.keys(mapOptions.lists).map((listType) => ({
      type: listType,
      markers: [],
    }));
  } else {
    return markersLists;
  }
}

export default async function buildMap(
  containerId,
  mapOptions = {},
  { markersLists, cameraPosition },
  mapUpdated
) {
  mapOptions = defaultMapOptions(containerId, mapOptions);
  markersLists = defaultMarkersLists(markersLists, mapOptions);

  const builder = {
    // Map
    map: mLib.create(containerId, mapOptions),
    // Map properties
    mapOptions,
    markersLists,
    cameraPosition,
    currentList: 0,
    // Interactivity
    list: function () {
      return this.markersLists[this.currentList];
    },
    options: function () {
      return this.mapOptions.lists[this.list().type];
    },
    markers: function () {
      return [...this.list().markers];
    },
    setMarkers: function (newMarkers) {
      this.list().markers = newMarkers;
      this.update();
    },
    setCameraPosition: function (position) {
      this.cameraPosition = position;
      this.update();
    },
    update: function () {
      if (mapUpdated) {
        mapUpdated(this.markersLists, this.cameraPosition);
      }
    },
    markerTypesMgr: markerTypesManagerSingleton(),
  };

  if (mapOptions.geocoder) {
    addGeocoder(builder);
  }

  const allMarkers = getAllMarkers(builder);
  const allLines = getAllLines(builder);
  await mLib.load(builder.map, allMarkers, allLines);

  jumpTo(builder, allMarkers);

  if (mapOptions.editable) {
    editableMarkers(builder);
  }

  await mLib.isRendered(builder.map);

  if (mapOptions.editable) {
    const setNewPosition = (newPosition) => {
      builder.setCameraPosition(newPosition);
    };
    mLib.followCameraPosition(
      builder.map,
      builder.cameraPosition,
      setNewPosition
    );
  }

  return {
    map: builder.map,
    getCanvas: () => mLib.getCanvas(builder.map),
    remove: () => mLib.remove(builder.map),
    setCurrentList: function (position) {
      builder.currentList = position;
      return this;
    },
  };
}

function getAllMarkers(builder) {
  return builder.markersLists.reduce((allMarkers, list) => {
    const listMarkers = list.markers.map((marker) => ({
      center: marker.center,
      bbox: marker.bbox,
      type: getMarkerType(builder, marker, list),
    }));

    return [...allMarkers, ...listMarkers];
  }, []);
}

function getAllLines(builder) {
  return builder.markersLists
    .filter((list) => builder.mapOptions.lists[list.type].link)
    .map((list, index) => ({
      id: index,
      coordinates: list.markers.map((marker) => marker.center),
    }));
}

// ==================================================================================================
// Camera Position
// ==================================================================================================

function jumpTo(builder, markers) {
  if (builder.cameraPosition) {
    const { center, zoom, pitch, bearing } = builder.cameraPosition;
    mLib.center(builder.map, center, zoom, pitch, bearing);
  } else {
    if (markers.length === 0) {
      mLib.center(builder.map, { lng: 12, lat: 33 }, 1);
    } else {
      const bbox = markers.reduce((bbox, marker) => {
        if (marker.bbox) {
          return [...bbox, ...marker.bbox];
        } else {
          return [...bbox, marker.center];
        }
      }, []);

      if (bbox.length === 1) {
        mLib.center(builder.map, bbox[0], 16);
      } else {
        mLib.fitBounds(builder.map, bbox);
      }
    }
  }
}

// ==================================================================================================
// Geocoder
// ==================================================================================================

function addGeocoder(builder) {
  mLib.addGeocoder(builder.map, {
    resultFound: ({ center, bbox }) => {
      clickOnMap(builder, center, bbox);
    },
  });
}

// ==================================================================================================
// Clusters
// ==================================================================================================

function getClustersPriority(lists) {
  return Object.keys(lists)
    .reduce((allMarkers, listKey) => {
      const list = lists[listKey];

      const markers = Object.keys(list.markers)
        .filter((key) => list.markers[key].isPin)
        .map((key) => {
          const marker = list.markers[key];
          return {
            type: key,
            priority: marker.clusterPriority ? marker.clusterPriority : 0,
            pinColor: marker.pinColor,
            icon: marker.icon,
          };
        });

      return [...allMarkers, ...markers];
    }, [])
    .sort((a, b) => b.priority - a.priority);
}

// ==================================================================================================
// Map Interactivity
// ==================================================================================================

function editableMarkers(builder) {
  mLib.editableMarkers(builder.map, {
    clickOnMap: (coordinates) => {
      clickOnMap(builder, coordinates);
    },
    clickOnMarker: (center) => {
      clickOnMarker(builder, center);
    },
    rightClickOnMarker: (center, properties) => {
      rightClickOnMarker(builder, center, properties);
    },
  });
}

function clickOnMap(builder, coordinates, bbox) {
  const { defaultType, firstType, lastType } = builder.markerTypesMgr();

  if (builder.options().link) {
    const markers = builder.markers();

    switch (markers.length) {
      case 0:
        addMarker(builder, coordinates, firstType, bbox);
        break;
      case 1:
        addMarker(builder, coordinates, lastType, bbox);
        break;
      default: {
        const lastMarker = getLastMarker(builder);
        updateMarker(builder, lastMarker.center, { type: defaultType });
        addMarker(builder, coordinates, lastType, bbox);
      }
    }

    drawLine(builder);
  } else {
    addMarker(builder, coordinates, defaultType, bbox);
  }
}

function clickOnMarker(builder, center) {
  removeMarker(builder, center);

  if (builder.options().link) {
    const { firstType, lastType } = builder.markerTypesMgr();

    if (builder.markers().length > 0) {
      const firstMarker = getFirstMarker(builder);
      if (firstMarker.type !== firstType) {
        updateMarker(builder, firstMarker.center, { type: firstType });
      }
    }

    if (builder.markers().length > 1) {
      const lastMarker = getLastMarker(builder);
      if (lastMarker.type !== lastType) {
        updateMarker(builder, lastMarker.center, { type: lastType });
      }
    }

    drawLine(builder);
  }
}

function rightClickOnMarker(builder, center, { pictogram }) {
  const custom = {};
  if (pictogram.isPin) {
    custom.pinColor = pictogram.code;
  } else {
    custom.icon = pictogram.code;
  }
  updateMarker(builder, center, { custom });
}

// ==================================================================================================
// Update Markers & Lines
// ==================================================================================================

function addMarker(builder, center, type, bbox) {
  const rawMarker = { center, type };
  if (bbox) {
    rawMarker.bbox = bbox;
  }

  const newMarker = roundMarkerCoordinates(rawMarker);
  builder.setMarkers([...builder.markers(), newMarker]);

  const markerType = getMarkerType(builder, newMarker);
  mLib.addMarker(builder.map, newMarker.center, markerType);
}

function removeMarker(builder, center) {
  const newMarkers = builder.markers().filter((marker) => {
    return !coordinatesAreEqual(marker.center, center);
  });
  if (newMarkers.length !== builder.markers().length) {
    builder.setMarkers(newMarkers);
    mLib.removeMarker(builder.map, center);
  }
}

function updateMarker(builder, center, newProperties) {
  let updatedMarker;
  const newMarkers = builder.markers().map((marker) => {
    if (coordinatesAreEqual(marker.center, center)) {
      updatedMarker = { ...marker, ...newProperties };
      if (newProperties.custom && marker.custom) {
        updatedMarker.custom = {
          ...marker.custom,
          ...newProperties.custom,
        };
      }
      return updatedMarker;
    }
    return marker;
  });
  builder.setMarkers(newMarkers);

  if (updatedMarker) {
    const markerType = getMarkerType(builder, updatedMarker);
    mLib.removeMarker(builder.map, center);
    mLib.addMarker(builder.map, center, markerType);
  }
}

function drawLine(builder) {
  const lineCoordinates = builder.markers().map((marker) => marker.center);
  mLib.drawLine(builder.map, builder.currentList, lineCoordinates);
}

// ==================================================================================================
// Marker Types Manager
// ==================================================================================================

function markerTypesManagerSingleton() {
  const managers = {};

  return function () {
    const listType = this.list().type;
    const markerTypes = this.options().markers;

    if (managers[listType] === undefined) {
      managers[listType] = getMarkerTypesManager(markerTypes);
    }
    return managers[listType];
  };
}

function getMarkerTypesManager(markerTypes) {
  const typeKeys = Object.keys(markerTypes);

  const getTypeByProperty = (property, defaultValue) => {
    const types = typeKeys.filter((type) => markerTypes[type][property]);
    return types.length > 0 ? types[0] : defaultValue;
  };

  const defaultType = getTypeByProperty('default', typeKeys[0]);

  return {
    defaultType,
    firstType: getTypeByProperty('first', defaultType),
    lastType: getTypeByProperty('last', defaultType),
  };
}

// ==================================================================================================
// Tools
// ==================================================================================================

function getMarkerType(builder, marker, list = undefined) {
  const listOptions = list
    ? builder.mapOptions.lists[list.type]
    : builder.options();

  const markerType = listOptions.markers[marker.type];
  return { type: marker.type, ...markerType, ...marker.custom };
}

function getFirstMarker(builder) {
  return builder.markers()[0];
}

function getLastMarker(builder) {
  return builder.markers().slice(-1)[0];
}

function roundMarkerCoordinates(marker) {
  const newMarker = { ...marker };

  newMarker.center = {
    lng: roundCoordinate(newMarker.center.lng),
    lat: roundCoordinate(newMarker.center.lat),
  };
  if (newMarker.bbox) {
    newMarker.bbox = newMarker.bbox.map((c) => ({
      lng: roundCoordinate(c.lng),
      lat: roundCoordinate(c.lat),
    }));
  }

  return newMarker;
}

function roundCoordinate(value) {
  return Number(Math.round(value + 'e7') + 'e-7');
}
