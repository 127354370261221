import './screenshots/doc-client-menu.png';
import './screenshots/doc-client-add.png';
import './screenshots/doc-country-menu.png';
import './screenshots/doc-country-add.png';
import './screenshots/doc-city-menu.png';
import './screenshots/doc-city-add.png';
import './screenshots/doc-program-menu.png';
import './screenshots/doc-program-add.png';
import './screenshots/doc-day-add-drag.gif';
import './screenshots/doc-day-add-drag-print.png';
import './screenshots/doc-day-add.png';
import './screenshots/doc-service-menu.png';
import './screenshots/doc-service-add.png';
import './screenshots/doc-activity-add-empty-1.png';
import './screenshots/doc-activity-add-empty-2.png';
import './screenshots/doc-activity-add-1.png';
import './screenshots/doc-activity-add-2.png';
import './screenshots/doc-activity-add-3.png';
import './screenshots/doc-currency-menu.png';
import './screenshots/doc-currency-add.png';
import './screenshots/doc-cotation-add-1.png';
import './screenshots/doc-cotation-add-2.png';
import './screenshots/doc-cotation-add-3.png';
import './screenshots/doc-program-print-1.png';
import './screenshots/doc-program-print-2.png';
