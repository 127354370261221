export default class MapboxCameraPosition {
  constructor(cameraPosition) {
    this.hideDeleteControl = !cameraPosition;
  }

  onAdd(map) {
    const icon = $('<span>')
      .addClass('mapboxgl-ctrl-icon')
      .attr('aria-hidden', 'true');
    const saveButton = $('<button>')
      .addClass('tp-table-map-camera-position-save')
      .attr('type', 'button')
      .attr('title', 'Save')
      .attr('aria-label', 'Save')
      .html(icon);
    const deleteButton = $('<button>')
      .addClass('tp-table-map-camera-position-delete')
      .attr('type', 'button')
      .attr('title', 'Delete')
      .attr('aria-label', 'Delete')
      .html(icon.clone());
    const container = $('<div>')
      .addClass(
        'mapboxgl-ctrl mapboxgl-ctrl-group tp-table-map-camera-position'
      )
      .html(saveButton)
      .append(deleteButton);

    if (this.hideDeleteControl) {
      container.addClass('tp-table-map-camera-position-no-delete');
    }

    this._map = map;
    this._container = container.get(0);
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }

  saveSelector() {
    return '.tp-table-map-camera-position-save';
  }

  deleteSelector() {
    return '.tp-table-map-camera-position-delete';
  }

  hideDelete() {
    $('.tp-table-map-camera-position').addClass(
      'tp-table-map-camera-position-no-delete'
    );
  }

  displayDelete() {
    $('.tp-table-map-camera-position').removeClass(
      'tp-table-map-camera-position-no-delete'
    );
  }
}
