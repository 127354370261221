export default {
  addPagination,
  displayDocument,
  hideDocument,
  notebookDisplay,
  printButton,
};

function addPagination() {
  // let nbSheets = $('.sheet').length;

  $('.sheet').each(function (index) {
    if (index > 0) {
      // let pagination = $('<div>').addClass('program-print-pagination').text((index + 1) + ' / ' + nbSheets);
      let logo = $('<div>')
        .addClass('program-print-pagination-logo')
        .append(
          $('#ShortLogoTemplate').clone().removeClass('hidden').removeAttr('id')
        );
      // $(this).append(pagination).append(logo);
      $(this).append(logo);
    }
  });
}

function displayDocument() {
  $('.program-print-loading').animate(
    {
      opacity: 0,
    },
    500,
    function () {
      $('.sheet').animate(
        {
          opacity: 1,
        },
        500
      );
    }
  );
}

function hideDocument() {
  $('.sheet').animate(
    {
      opacity: 0,
    },
    500,
    function () {
      $('.program-print-loading').animate(
        {
          opacity: 1,
        },
        500
      );
    }
  );
}

function notebookDisplay() {
  $('.program-print-notebook-option').click(function () {
    $(this)
      .find('button span:first-child')
      .toggleClass('glyphicon-unchecked glyphicon-check');
    $('.sheet').toggleClass('program-print-notebook');
  });
}

function printButton() {
  $('.program-print-print').click(function () {
    window.print();
  });
}
