import router from 'tools/router';
import './console.scss';

export function myConsole() {
  $(function () {
    initialize();
  });
}

function initialize() {
  const input = document.querySelector('.tp-core-console-command input');
  let running = false;

  if (input) {
    input.addEventListener('keydown', (event) => {
      if (!running && event.keyCode === 13) {
        const command = input.value;

        if (command.length > 0) {
          running = true;
          input.readOnly = true;

          $.post(
            //URL
            router.generate('tp_core_console_exe_AJAX'),
            // Data
            { command },
            // Success
            function (result) {
              const executed = $('.tp-core-console-executed');
              executed.find('pre').addClass('hidden');
              executed
                .append(formatResult(command, result))
                .scrollTop(executed.prop('scrollHeight'));

              input.value = '';
              input.readOnly = false;
              running = false;
            }
          );
        }
      }
    });
  }

  $('.tp-core-console-executed').on(
    'click',
    '.tp-core-console-executed-command',
    function () {
      $(this).parent().find('pre').toggleClass('hidden');
    }
  );
}

function formatResult(command, rawOutput) {
  const title = $('<div>')
    .addClass('tp-core-console-executed-command')
    .text(command);
  const output = $('<pre>').addClass('ansi_color_bg_black').html(rawOutput);
  return $('<div>').append(title).append(output);
}
