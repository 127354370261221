import { form } from './form';
import { buttonsBar } from './buttons-bar/buttons-bar';
import { crud } from './crud/crud';
import { defineImageGridEvents } from './image-grid';
import { entityPopup } from './entity-popup/entity-popup';

export function table() {
  form();
  buttonsBar();
  crud();
  defineImageGridEvents();
  entityPopup();
}
