import datatables from 'tools/datatables';

import './css/delete.scss';
import './css/list.scss';
import './css/zoom.scss';

export function crud() {
  $(function () {
    // List
    datatables.display();
  });
}
