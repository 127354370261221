import display from './display.js';
import { JSONParse } from 'tools/utilities.js';

let pageBreaks;

export default {
  removeEmptySelectableElements,
  initialize,
  scrollToClickedPageBreak,
};

// API
function removeEmptySelectableElements() {
  $('.program-print-pagebreak-break-selectable').each(function () {
    if ($(this).height() === 0) $(this).remove();
  });
}

function initialize() {
  pageBreaks = getPageBreaksInForm();

  $(
    '.program-print-pagebreak-break-selectable .program-print-pagebreak-break-action'
  ).click(function () {
    updateDisplay($(this), addPageBreak);
  });

  $(
    '.program-print-pagebreak-break-selected .program-print-pagebreak-break-action'
  ).click(function () {
    updateDisplay($(this), removePageBreak);
  });

  $(
    '.program-print-pagebreak-hide-selectable .program-print-pagebreak-hide-action'
  ).click(function () {
    updateDisplay($(this), hideElement);
  });

  $(
    '.program-print-pagebreak-hide-selected .program-print-pagebreak-hide-action'
  ).click(function () {
    updateDisplay($(this), displayElement);
  });
}

function scrollToClickedPageBreak() {
  const clickedElementId = $('#page_break_clickedPageBreak').val();

  if (clickedElementId) {
    window.location.hash = '#' + clickedElementId;
    window.scrollBy(0, -100);
  }
}

// Actions
function updateDisplay(element, action) {
  display.hideDocument();

  const elementId = getElementId(element);
  action(elementId);
  submitForm(elementId);
}

function addPageBreak(elementId) {
  pageBreaks.break.push(elementId);
}

function removePageBreak(elementId) {
  pageBreaks.break = pageBreaks.break.filter((pb) => pb !== elementId);
}

function hideElement(elementId) {
  pageBreaks.hidden.push(elementId);
  removePageBreak(elementId);
}

function displayElement(elementId) {
  pageBreaks.hidden = pageBreaks.hidden.filter((pb) => pb !== elementId);
}

// Form
function getPageBreaksInForm() {
  const pageBreaksInForm = $('#page_break_pageBreaks').val();
  return JSONParse(pageBreaksInForm, { break: [], hidden: [] });
}

function submitForm(elementId) {
  $('#page_break_pageBreaks').val(JSON.stringify(pageBreaks));
  $('#page_break_clickedPageBreak').val(elementId);

  const urlWithoutAnchor = window.location.href.replace(/#.*$/, '');
  $('form[name="page_break"]').attr('action', urlWithoutAnchor).submit();
}

// Tools
function getElementId(element) {
  return element.parent().parent().attr('id');
}
