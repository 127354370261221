import dialog from 'tools/dialog';

export function maintenance() {
  $(function () {
    initialize();
  });
}

const submitId = 'MaintenanceSubmitButton';
const cancelId = 'MaintenanceCancel';

function initialize() {
  $('#MaintenanceAskConfirmation').click(function (event) {
    event.preventDefault();

    const buttonYes = $('<button>')
      .attr('id', submitId)
      .addClass('btn btn-danger btn-sm')
      .text($(this).data('confirmation-yes'));
    const buttonNo = $('<button>')
      .attr('id', cancelId)
      .addClass('btn btn-default btn-sm')
      .text($(this).data('confirmation-no'));
    const content = $('<div>')
      .addClass('col-sm-12')
      .append(buttonYes)
      .append(buttonNo);

    dialog.open($(this).data('confirmation-question'), content);
  });

  $(dialog.DIALOG_ID).on('click', '#' + submitId, function () {
    dialog.loading($('#MaintenanceAskConfirmation').data('running-title'));

    $('form').submit();
  });

  $(dialog.DIALOG_ID).on('click', '#' + cancelId, function () {
    dialog.close();
  });
}
