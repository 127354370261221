import buildMap from 'tools/map';
import { JSONParse } from 'tools/utilities.js';

export function defineMapEvents() {
  displayZoomMap();
  displayFormMap();

  // Prevent Form Submission from the Map
  $('.tp-table-map-container').on('keyup keypress', 'input', function (e) {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      e.preventDefault();
      return false;
    }
  });
}

// ****************************************
// Display Location Value
// ****************************************
export function displayMapInputValue(container = '') {
  $(container + ' .tp-table-map').each(function () {
    const input = $(this).find('input');
    const { markersLists } = getInputValue(input);
    const container = $('#tp_table_map_' + input.attr('id'));
    const optionsLists = container.data('map-options').lists;
    displayFormattedInput(input, markersLists, optionsLists);
  });
}

function displayFormattedInput(input, markersLists, optionsLists) {
  const container = $('#tp_table_map_location_' + input.attr('id'));
  container.text(formatMarkersLists(markersLists, optionsLists));
}

function displayZoomValue() {
  $('.tp-table-map-zoom').each(function () {
    const container = getZoomContainer($(this));

    const { markersLists } = container.data('status');
    const optionsLists = container.data('map-options').lists;
    $(this)
      .find('.tp-table-map-location-zoom')
      .text(formatMarkersLists(markersLists, optionsLists));
  });
}

// ****************************************
// Display Map
// ****************************************
function displayZoomMap() {
  displayZoomValue();

  $('.tp-table-map-zoom').click(function () {
    const container = getZoomContainer($(this));

    const containerId = container.attr('id');
    const options = {
      interactive: true,
      ...container.data('map-options'),
    };
    const mapStatus = container.data('status');

    toggleMap(containerId, options, mapStatus);
  });
}

function displayFormMap() {
  $('.table-form-row').on('click', '.tp-table-map', function () {
    const input = $(this).find('input');
    const containerId = 'tp_table_map_' + input.attr('id');

    const mapContainer = $('#' + containerId);
    const options = {
      interactive: true,
      editable: mapContainer.data('editable'),
      geocoder: true,
      ...mapContainer.data('map-options'),
    };
    const mapStatus = getInputValue(input);

    const updateInput = (markersLists, cameraPosition) => {
      setInputValue(input, markersLists, cameraPosition);
      displayFormattedInput(input, markersLists, options.lists);
    };

    toggleMap(containerId, options, mapStatus, updateInput);
  });
}

function toggleMap(containerId, options, mapStatus, updateInput) {
  const mapContainer = $('#' + containerId);
  mapContainer.toggleClass('hidden');

  // Load the map if needed
  if (!mapContainer.hasClass('hidden') && !mapContainer.data('loaded')) {
    buildMap(containerId, options, mapStatus, updateInput);
    mapContainer.data('loaded', true);
  }
}

// ****************************************
// Tools
// ****************************************

function getMarkersNb(markersLists) {
  return markersLists.reduce((nb, list) => {
    return nb + list.markers.length;
  }, 0);
}

function formatMarkersLists(markersLists, listsOptions) {
  if (getMarkersNb(markersLists) === 0) return '';

  const formattedLists = markersLists.map((markersList) => {
    const listOptions = listsOptions[markersList.type];

    if (listOptions.link) {
      return formatLinkedList(markersList, listOptions);
    } else {
      return formatUnlinkedList(markersList, listOptions);
    }
  });

  return formattedLists.join(', ');
}

function formatLinkedList(markersList, listOptions) {
  const markerTypes = listOptions.markers;
  const stepType = Object.keys(markerTypes).filter(
    (type) => !(markerTypes[type].first || markerTypes[type].last)
  )[0];
  const stepLabel = markerTypes[stepType].label.toLowerCase();

  const markers = markersList.markers;
  const nbSteps = markers.filter((m) => m.type === stepType).length + 1;
  const finalLabel = nbSteps > 1 ? getPlural(stepLabel) : stepLabel;

  return `${listOptions.label}: ${nbSteps} ${finalLabel}`;
}

function formatUnlinkedList(markersList, listOptions) {
  const markerTypes = listOptions.markers;
  const formattedTypes = Object.keys(markerTypes).map((type) => {
    const label = listOptions.markers[type].label.toLowerCase();

    const markers = markersList.markers;
    const nbMarkers = markers.filter((m) => m.type === type).length;
    const finalLabel = nbMarkers > 1 ? getPlural(label) : label;

    return `${nbMarkers} ${finalLabel}`;
  });

  return `${listOptions.label}: ${formattedTypes.join(', ')}`;
}

function getPlural(label) {
  const words = label.split(' ');
  words[0] += 's';
  return words.join(' ');
}

function getInputValue(input) {
  return JSONParse(input.val(), { markersLists: [] });
}

function setInputValue(input, markersLists, cameraPosition) {
  input.val(formatInputValue(markersLists, cameraPosition));
}

function formatInputValue(markersLists, cameraPosition) {
  if (getMarkersNb(markersLists) === 0) {
    return null;
  } else {
    return JSON.stringify(
      cameraPosition ? { markersLists, cameraPosition } : { markersLists }
    );
  }
}

function getZoomContainer(mapNode) {
  return mapNode.parent().find('.tp-table-map-container-zoom');
}
