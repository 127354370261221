import mapboxgl from 'mapbox-gl';
import icon_delete from '../../images/delete.png';

export function popupMgr() {
  let popup;
  let isOpen = false;
  const setIsOpen = (newIsOpen) => {
    isOpen = newIsOpen;
  };

  return {
    build: (map, coordinates, isPin, content) => {
      popup = newPopup(setIsOpen, map, coordinates, isPin, content);
    },
    close: () => popup.remove(),
    isOpen: () => isOpen,
  };
}

function newPopup(setIsOpen, map, coordinates, isPin, content) {
  const offset = getPopupOffset(isPin);
  const popup = new mapboxgl.Popup({ offset, maxWidth: '300px' });
  return popup
    .setLngLat(coordinates)
    .setHTML(content)
    .on('open', () => {
      setIsOpen(true);
    })
    .on('close', () => {
      setIsOpen(false);
    })
    .addTo(map);
}

function getPopupOffset(isPin) {
  if (!isPin) return 10;

  const markerHeight = 40;
  const markerRadius = 13;
  const linearOffset = 9;

  return {
    top: [0, 0],
    'top-left': [0, 0],
    'top-right': [0, 0],
    bottom: [0, -markerHeight],
    'bottom-left': [
      linearOffset,
      (markerHeight - markerRadius + linearOffset) * -1,
    ],
    'bottom-right': [
      -linearOffset,
      (markerHeight - markerRadius + linearOffset) * -1,
    ],
    left: [markerRadius, (markerHeight - markerRadius) * -1],
    right: [-markerRadius, (markerHeight - markerRadius) * -1],
  };
}

// Delete Popup
function deleteContent(publicUrl = '') {
  return $('<div>')
    .addClass('tp-table-map-marker-delete')
    .append($('<img>').attr('src', publicUrl + icon_delete))
    .append($('<span>').text('Supprimer'))
    .prop('outerHTML');
}

function setClickEvent(containerId, center, popup, click) {
  $(`#${containerId} .tp-table-map-marker-delete`).click(function () {
    popup.close();
    click(center);
  });
}

export const deletePopup = { content: deleteContent, setClickEvent };

// Cluster Popup
const clusterContent = $('<div>')
  .addClass('tp-table-map-marker-cluster')
  .append($('<div>').text('Ce marqueur est un cluster.'))
  .append($('<div>').text('Zoomez sur la carte pour pouvoir intéragir.'))
  .prop('outerHTML');

export const clusterPopup = { content: clusterContent };
