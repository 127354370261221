import { initializeFormFields } from 'table/form';

// Subform Indexes insuring the Lines Unicity inside each Subform
let subformArrayIndexes = {};

function incrementSubformIndex(subformArrayId) {
  if (subformArrayIndexes[subformArrayId] === undefined) {
    subformArrayIndexes[subformArrayId] = 0;
  } else {
    subformArrayIndexes[subformArrayId]++;
  }

  return subformArrayIndexes[subformArrayId];
}

export function initializeSubformAsArray() {
  displaySubformAsArray();
  defineEvents();
}

export function displaySubformAsArray(container = '') {
  $(container + ' .tp-table-subform-array').each(function () {
    if (!$(this).data('subform-loaded')) {
      // Initialisation
      let container = $(this);
      let subformArrayId = container.attr('id');

      // Get Container Data
      let prototype = container.attr('data-prototype');
      let children = container.children('.form-group');

      // Display the Subform
      container.parent().parent().children('label').removeClass('required');

      container.append(createTable(subformArrayId, prototype, children));
      container.append(createAddLine(subformArrayId));

      // Initialize
      initializeFormFields('#' + subformArrayId);

      // Loaded
      container.data('subform-loaded', true);
    }
  });
}

function createTable(subformArrayId, prototype, children) {
  // Table Container
  let table = $('<div>')
    .addClass('form-group')
    .attr('id', subformArrayId + '_table');

  // Table Header
  let header = createTableHeader(subformArrayId, prototype);
  table.append(header);

  // Organise the Input Fields of the Existing Elements into Lines
  children.each(function () {
    let position = incrementSubformIndex(subformArrayId);
    table.append(createLine(subformArrayId, position, $(this)));
  });

  return table;
}

function createTableHeader(subformArrayId, prototype) {
  // Containers
  let header = $('<div>').attr('id', subformArrayId + '_subform_header');
  let whiteSpace = $('<div>').addClass('col-sm-1');
  let labelsContainer = $('<div>').addClass('col-sm-11');
  let labels = $('<div>').addClass('form-group');

  // Get Labels
  $(prototype)
    .find('label[for^="' + subformArrayId + '"]')
    .each(function () {
      let width = $(this).data('width');
      if (width === undefined) {
        width = 'col-sm-3';
      }

      $(this)
        .removeAttr('for')
        .addClass('tp-table-subform-array-label')
        .removeClass('control-label')
        .removeClass('col-sm-2')
        .addClass(width);

      labels.append($(this));
    });

  // Build Header
  header.append(whiteSpace);
  header.append(labelsContainer);
  labelsContainer.append(labels);

  return header;
}

function createLine(subformArrayId, position, child) {
  // Initialisation
  let lineId = subformArrayId + '_subform_line_' + position;

  // Line Content
  let deleteButton = createDeleteButton();
  let childContainer = $('<div>').addClass('form-group');

  // Find each Field and add it to the Child Container
  child.find('.col-sm-10 .col-sm-10').each(function () {
    // Resize Field
    let width = $(this).find('select, input').data('width');
    if (width === undefined) {
      width = 'col-sm-3';
    }
    $(this).addClass(width).removeClass('col-sm-10');

    // Manage Errors on Field
    if ($(this).parent().hasClass('has-error')) {
      $(this).addClass('has-error');
    }

    // Move Field
    childContainer.append($(this));
  });

  // Build the Line
  let line = $('<div>')
    .attr('id', lineId)
    .addClass('tp-table-subform-array-line');
  let deleteButtonWrapper = $('<div>').addClass('col-sm-1');
  let childWrapper = $('<div>').addClass('col-sm-11');

  deleteButtonWrapper.append(deleteButton);
  childWrapper.append(childContainer);
  line.append(deleteButtonWrapper);
  line.append(childWrapper);

  // Delete the Remaining of the Child
  child.remove();

  return line;
}

function createDeleteButton() {
  let deleteButton = $('<button>')
    .attr('type', 'button')
    .addClass('col-sm-12 btn btn-danger glyphicon glyphicon-trash')
    .addClass('tp-table-subform-array-delete');

  return deleteButton;
}

function createAddLine(subformArrayId) {
  let formGroup = $('<div>')
    .addClass('form-group')
    .addClass('tp-table-subform-array-add')
    .data('subform-id', subformArrayId);

  let col = $('<div>').addClass('col-sm-12');
  formGroup.append(col);

  let addButton = $('<button>')
    .attr('type', 'button')
    .addClass('col-sm-1 btn btn-success glyphicon glyphicon-plus');
  col.append(addButton);

  return formGroup;
}

function defineEvents() {
  // Add a Line
  $('.table-form-row').on('click', '.tp-table-subform-array-add', function () {
    let subformArrayId = $(this).data('subform-id');
    addNewChild(subformArrayId);
  });

  //Remove a Line
  $('.table-form-row').on(
    'click',
    '.tp-table-subform-array-delete',
    function () {
      let container = $(this).closest('.tp-table-subform-array-line');
      $(container).remove();
    }
  );
}

function addNewChild(subformArrayId) {
  // Initialisation
  let container = $('#' + subformArrayId);
  let prototype = container.attr('data-prototype');

  let table = $('#' + subformArrayId + '_table');
  let position = incrementSubformIndex(subformArrayId);

  // Add a new Subform Element
  let newLine = $(
    prototype
      .replace(/__name__label__/g, position)
      .replace(/__name__/g, position)
  );
  table.append(createLine(subformArrayId, position, newLine));
}
