import datatables from 'tools/datatables';
import dialog from 'tools/dialog';

import './homepage.scss';

export function homepage() {
  $(function () {
    initialize();
  });
}

function initialize() {
  myPrograms();
  nextMonthDeparture();
  programStatus();
  ownersRevenue();
}

function myPrograms() {
  let options = {
    searching: false,
    paging: false,
    info: false,
    columnDefs: [
      { visible: false, targets: [4, 8, 10, 11] },
      { orderable: false, targets: '_all' },
    ],
  };
  options.columnDefs.push({ orderData: 11, targets: 8 });

  datatables.display('list', '.card.tp-myPrograms', options);
}

function nextMonthDeparture() {
  let options = {
    searching: false,
    paging: false,
    info: false,
    columnDefs: [
      { visible: false, targets: [8, 10, 11] },
      { orderable: false, targets: '_all' },
    ],
  };
  options.columnDefs.push({ orderData: 9, targets: 2 });

  datatables.display('list', '.card.tp-nextMonthDepartures', options);
}

function programStatus() {
  let popupContent = $('#ProgramsStatusForWholeTeam').clone();
  $('#ShowProgramsStatusForWholeTeam').click(function () {
    dialog.open($(this).data('title'), popupContent, 700, true);
  });
}

function ownersRevenue() {
  let popupContent = $('#OwnersRevenueForWholeTeam').clone();
  $('#ShowOwnersRevenueForWholeTeam').click(function () {
    dialog.open($(this).data('title'), popupContent, 700, true);
  });

  $('#ShowOwnerRevenue').click(function () {
    $(this).toggle();
    $(this).prev().toggleClass('invisible');
  });
}
