export function JSONParse(value, defaultValue) {
  if (value !== '') {
    try {
      return JSON.parse(value);
    } catch (error) {
      console.error(error);
      console.error('JSON value', value);
    }
  }
  return defaultValue;
}

export function debounce(func, wait, immediate = false) {
  let timeout;

  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

// Cookies Management
export function getCookie(label, defaultValue) {
  if (!label) return '';

  const cookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith(label));

  if (cookie) {
    const encodedValue = cookie.split('=')[1];
    return JSONParse(window.atob(encodedValue), defaultValue);
  } else {
    return defaultValue;
  }
}

export function setCookie(label, value, duration = 2592000) {
  const cookie = [];
  const encodedValue = window.btoa(JSON.stringify(value));
  cookie.push(`${label}=${encodedValue}`);
  cookie.push('path=/');
  cookie.push(`max-age=${duration}`);
  if (location.protocol === 'https:') {
    cookie.push('secure');
  }

  const cookieString = cookie.join('; ');
  document.cookie = cookieString;
  return cookieString;
}

export function deleteCookie(label) {
  const cookie = [];
  cookie.push(`${label}=`);
  cookie.push('path=/');
  cookie.push('expires=Thu, 01 Jan 1970 00:00:00 GMT');
  if (location.protocol === 'https:') {
    cookie.push('secure');
  }

  const cookieString = cookie.join('; ');
  document.cookie = cookieString;
  return cookieString;
}
