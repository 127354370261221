// Pin Shadow
import pin_shadow from '../images/pins/shadow.png';

// Pin Colors
import pin_yellow from '../images/pins/yellow.png';
import pin_orange from '../images/pins/orange.png';
import pin_red from '../images/pins/red.png';
import pin_pink from '../images/pins/pink.png';
import pin_purple from '../images/pins/purple.png';
import pin_blue from '../images/pins/blue.png';
import pin_green from '../images/pins/green.png';
import pin_brown from '../images/pins/brown.png';
import pin_grey from '../images/pins/grey.png';

// Location
import icon_art from '../images/icons/location_art.png';
import icon_atm from '../images/icons/location_atm.png';
import icon_gasStation from '../images/icons/location_gas_station.png';
import icon_grocery from '../images/icons/location_grocery.png';
import icon_harbourg from '../images/icons/location_harbourg.png';
import icon_health from '../images/icons/location_health.png';
import icon_hotel from '../images/icons/location_hotel.png';
import icon_museum from '../images/icons/location_museum.png';
import icon_music from '../images/icons/location_music.png';
import icon_parking from '../images/icons/location_parking.png';
// Transport
import icon_airport from '../images/icons/transport_airport.png';
import icon_boat from '../images/icons/transport_boat.png';
import icon_flightLD from '../images/icons/transport_flight_landing.png';
import icon_flightTO from '../images/icons/transport_flight_takeoff.png';
import icon_subway from '../images/icons/transport_subway.png';
import icon_taxi from '../images/icons/transport_taxi.png';
import icon_train from '../images/icons/transport_train.png';
import icon_school from '../images/icons/location_school.png';
// Food
import icon_bar from '../images/icons/food_bar.png';
import icon_cafe from '../images/icons/food_cafe.png';
import icon_fastfood from '../images/icons/food_fastfood.png';
import icon_restaurant from '../images/icons/food_restaurant.png';
// Nature
import icon_animal from '../images/icons/nature_animal.png';
import icon_beach from '../images/icons/nature_beach.png';
import icon_flower from '../images/icons/nature_flower.png';
import icon_forest from '../images/icons/nature_forest.png';
// Sport
import icon_bike from '../images/icons/sport_bike.png';
import icon_cricket from '../images/icons/sport_cricket.png';
import icon_fitness from '../images/icons/sport_fitness.png';
import icon_golf from '../images/icons/sport_golf.png';
import icon_motorsport from '../images/icons/sport_motorsport.png';
import icon_rowing from '../images/icons/sport_rowing.png';
import icon_tennis from '../images/icons/sport_tennis.png';
// Miscellaneous
import icon_a from '../images/icons/miscellaneous_a.png';
import icon_d from '../images/icons/miscellaneous_d.png';
import icon_dot from '../images/icons/miscellaneous_dot.png';
import icon_favorite from '../images/icons/miscellaneous_favorite.png';
import icon_star from '../images/icons/miscellaneous_star.png';
import icon_videogame from '../images/icons/miscellaneous_videogame.png';
import icon_viewPoint from '../images/icons/miscellaneous_view_point.png';

const pins = {
  shadow: { code: 'shadow', name: 'Shadow', url: pin_shadow },
  colors: [
    { code: 'yellow', name: 'Yellow', url: pin_yellow },
    { code: 'orange', name: 'Orange', url: pin_orange },
    { code: 'red', name: 'Red', url: pin_red },
    { code: 'pink', name: 'Pink', url: pin_pink },
    { code: 'purple', name: 'Purple', url: pin_purple },
    { code: 'blue', name: 'Blue', url: pin_blue },
    { code: 'green', name: 'Green', url: pin_green },
    { code: 'brown', name: 'Brown', url: pin_brown },
    { code: 'grey', name: 'Grey', url: pin_grey },
  ],
};
const icons = {
  location: [
    { code: 'hotel', name: 'Hotel', url: icon_hotel },
    { code: 'atm', name: 'ATM', url: icon_atm },
    { code: 'grocery', name: 'Grocery', url: icon_grocery },
    { code: 'gasStation', name: 'Gas station', url: icon_gasStation },
    { code: 'museum', name: 'Museum', url: icon_museum },
    { code: 'art', name: 'Art', url: icon_art },
    { code: 'music', name: 'Music', url: icon_music },
    { code: 'school', name: 'School', url: icon_school },
    { code: 'health', name: 'Health', url: icon_health },
    { code: 'harbourg', name: 'Harbourg', url: icon_harbourg },
    { code: 'parking', name: 'Parking', url: icon_parking },
  ],
  transport: [
    { code: 'airport', name: 'Restaurant', url: icon_airport },
    { code: 'flightLD', name: 'Flight landing', url: icon_flightLD },
    { code: 'flightTO', name: 'Flight Takeoff', url: icon_flightTO },
    { code: 'boat', name: 'Fastfood', url: icon_boat },
    { code: 'taxi', name: 'Taxi', url: icon_taxi },
    { code: 'train', name: 'Train', url: icon_train },
    { code: 'subway', name: 'Subway', url: icon_subway },
  ],
  food: [
    { code: 'restaurant', name: 'Restaurant', url: icon_restaurant },
    { code: 'fastfood', name: 'Fastfood', url: icon_fastfood },
    { code: 'bar', name: 'Bar', url: icon_bar },
    { code: 'cafe', name: 'Cafe', url: icon_cafe },
  ],
  nature: [
    { code: 'beach', name: 'Beach', url: icon_beach },
    { code: 'forest', name: 'Forest', url: icon_forest },
    { code: 'flower', name: 'Flower', url: icon_flower },
    { code: 'animal', name: 'Animal', url: icon_animal },
  ],
  sport: [
    { code: 'bike', name: 'Bike', url: icon_bike },
    { code: 'tennis', name: 'Tennis', url: icon_tennis },
    { code: 'cricket', name: 'Cricket', url: icon_cricket },
    { code: 'golf', name: 'Golf', url: icon_golf },
    { code: 'rowing', name: 'Rowing', url: icon_rowing },
    { code: 'fitness', name: 'Fitness', url: icon_fitness },
    { code: 'motorsport', name: 'Motorsport', url: icon_motorsport },
  ],
  miscellaneous: [
    { code: 'viewPoint', name: 'View point', url: icon_viewPoint },
    { code: 'a', name: 'A', url: icon_a },
    { code: 'd', name: 'D', url: icon_d },
    { code: 'dot', name: 'Dot', url: icon_dot },
    { code: 'favorite', name: 'Favorite', url: icon_favorite },
    { code: 'star', name: 'Star', url: icon_star },
    { code: 'videogame', name: 'Videogame', url: icon_videogame },
  ],
};

export const pictograms = {
  getList,
  popup: {
    content: getPopupContent,
    setClickEvent,
  },
};

// Get List
function getList(publicUrl = '') {
  const iconsList = Object.keys(icons).reduce((list, key) => {
    return list.concat(icons[key]);
  }, []);

  const list = [pins.shadow, ...pins.colors, ...iconsList];
  return extendUrl(list, publicUrl);
}

// Build Popup
function getPopupContent(publicUrl = '', isPin = false) {
  const container = $('<div>').addClass('tp-map-pictograms-container');
  const extdUrl = (list) => extendUrl(list, publicUrl);

  const iconsContainer = $('<div>').append(buildTitle('Icons'));
  container.append(iconsContainer);

  const lines = [
    [buildList(extdUrl(icons.location))],
    [buildList(extdUrl(icons.transport)), buildList(extdUrl(icons.food))],
    [buildList(extdUrl(icons.nature)), buildList(extdUrl(icons.sport))],
    [buildList(extdUrl(icons.miscellaneous))],
  ];
  lines.forEach((line) => {
    iconsContainer.append(buildLine(line));
  });

  if (isPin) {
    const pinsContainer = $('<div>').append(buildTitle('Pins'));
    container.append(pinsContainer);

    extdUrl(pins.colors).forEach((pin) => {
      pinsContainer.append(buildImg(pin, true));
    });
  }

  return container.prop('outerHTML');
}

function buildTitle(title) {
  return $('<div>').addClass('tp-map-pictograms-title').text(title);
}

function buildImg(pictogram, isPin = false) {
  return $('<img>')
    .addClass('tp-map-pictograms-img')
    .attr('src', pictogram.url)
    .attr('title', pictogram.name)
    .attr('alt', pictogram.name)
    .attr('data-is-pin', isPin)
    .attr('data-code', pictogram.code);
}

function buildList(list) {
  const listContainer = $('<div>');
  list.forEach((icon) => {
    listContainer.append(buildImg(icon));
  });
  return listContainer;
}

function buildLine(lists) {
  const lineContainer = $('<div>').addClass('tp-map-pictograms-line');
  lists.forEach((line) => {
    lineContainer.append(line);
  });
  return lineContainer;
}

// Event
function setClickEvent(containerId, center, click) {
  $(`#${containerId} .tp-map-pictograms-img`).click(function () {
    click(center, {
      pictogram: {
        isPin: $(this).data('is-pin'),
        code: $(this).data('code'),
      },
    });
  });
}

// Tools
function extendUrl(list, publicUrl = '') {
  return list.map((p) => ({
    ...p,
    url: publicUrl + p.url,
  }));
}
