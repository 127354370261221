import dialog from 'tools/dialog';

import './dolibarr.scss';

export function dolibarr() {
  $(function () {
    printInNewTab();
    administration();
  });
}

function printInNewTab() {
  if (window.self !== window.top) {
    $('.program-header #Program-print').click(function () {
      window.open($(this).attr('href'));
      return false;
    });
  }
}

function administration() {
  $('#DolibarrSubmitButton').click(function () {
    dialog.loading($(this).data('running-title'));
  });
}
