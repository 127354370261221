let router;

try {
  const fos_router = require('project-dir/vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.js');
  const routerConfig = require('project-dir/public/bundles/fosjsrouting/fos_js_routes.json');

  fos_router.setRoutingData(routerConfig);
  router = fos_router;
} catch (e) {
  if (e.code !== 'MODULE_NOT_FOUND') {
    throw e;
  }
  class EmptyRouter {
    generate() {
      throw 'Routes are not exposed, run "npm run fos-js-routes" to fix.';
    }
  }
  router = new EmptyRouter();
}

export default router;
