import { newNode } from './split-sheets-node.js';

export function splitSheets(nodeActions = {}) {
  $('section').each(function () {
    const sheet = newNode($(this));
    splitSheetContent(sheet, nodeActions);
  });
}

function splitSheetContent(sheet, nodeActions = {}) {
  // Initialisation
  let nextSheet;
  const sheetBottomOffset = sheet.bottomOffset();

  sheet.getChildren().each(function () {
    const child = newNode($(this));

    // If no child has been moved already
    if (!nextSheet) {
      // Check if there is an action to execute on this child
      for (const selector in nodeActions) {
        if (child.is(selector)) {
          nodeActions[selector](child.DOM);
        }
      }

      // Check if the current child has to be moved
      if (childHasToMove(sheetBottomOffset, child, true)) {
        nextSheet = createNextSheet(sheet);
        moveChild(nextSheet, child, sheetBottomOffset, true);
      }
    } else {
      // Move all following childs
      moveChild(nextSheet, child, sheetBottomOffset, false);
    }
  });

  // Same process done on the new sheet
  if (nextSheet) splitSheetContent(nextSheet, nodeActions);
}

function childHasToMove(sheetBottomOffset, child, isOriginalChild) {
  if (isOriginalChild) {
    if (child.isFirstChild()) {
      // The first child of a page never has to move if it is not splittable
      if (!child.isContentSplittable()) return false;
    } else {
      // If the child is selected as a pageBreak and it is not the first child, then it has to move
      if (child.isPageBreakSelected()) return true;
    }
  }

  // Check if child's bottom overflows
  if (child.bottomOffset() > sheetBottomOffset) {
    // If yes, deal with the special case of a description element following a title (see else case below)
    if (!isOriginalChild && child.isContentSplittable()) {
      // Check if the section containing only the first paragraph fits in the first page
      const copy = child.copy();
      const firstParagraph = copy.getChildren()[0];
      copy.setContent(firstParagraph);

      copy.beforeNode(child);
      const copyOffset = copy.bottomOffset();
      copy.delete();

      return copyOffset > sheetBottomOffset;
    }

    return true;
  } else {
    // No overflow, if the object is a title then check if it has dependencies and if they are to be moved
    if (child.isTitle()) {
      const nextChild = child.next();

      if (
        nextChild && // If the next element exists
        !nextChild.isPageBreakSelected() && // And it is not selected as a pageBreak
        nextChild.dependsOn(child) // And it is a dependency of the title
      ) {
        // If it is to be moved, then move the title so that the page doesn't end on a title
        return childHasToMove(sheetBottomOffset, nextChild, false);
      }
    }

    return false;
  }
}

function moveChild(nextSheet, child, sheetBottomOffset, firstChildMoved) {
  // Special case of a splittable child
  if (firstChildMoved && child.isContentSplittable()) {
    // Divide the child into paragraphs
    const paragraphs = child.getChildren();

    for (let i = 0; i < paragraphs.length; i++) {
      const paragraph = newNode($(paragraphs[i]));

      // Split the child in 2 taking into account title dependencies
      if (childHasToMove(sheetBottomOffset, paragraph, false)) {
        if (i > 0) {
          const firstPart = paragraphs.slice(0, i);
          child.setContent(firstPart);

          const secondPart = paragraphs.slice(i);
          child = child.copy().setContent(secondPart);
        }
        break;
      }
    }
  }

  // Move the child in the next sheet
  child.appendToNode(nextSheet);
}

function createNextSheet(sheet) {
  return sheet.copy(false).afterNode(sheet);
}
