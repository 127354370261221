import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxCameraPosition from './mapbox-gl-camera-position';

import { coordinatesAreEqual } from '../tools';
import { JSONParse, debounce } from 'tools/utilities.js';
import { popupMgr, deletePopup, clusterPopup } from './popup-manager';

// ==================================================================================================
// API
// ==================================================================================================

export default {
  create,
  remove,
  load,
  isRendered,
  getCanvas,
  center,
  fitBounds,
  addGeocoder,
  editableMarkers,
  addMarker,
  removeMarker,
  drawLine,
  followCameraPosition,
};

// ==================================================================================================
// Load & Display Map
// ==================================================================================================

function create(
  containerId,
  { interactive, printable, scroll, pictograms, clusters, token, publicUrl }
) {
  mapboxgl.accessToken = token;

  const map = new mapboxgl.Map({
    container: containerId,
    preserveDrawingBuffer: printable,
    maxZoom: 17,
    style: 'mapbox://styles/mapbox/streets-v10', //v11 and following do not work with MapboxLanguage
    interactive,
  });

  map.addControl(new MapboxLanguage({ defaultLanguage: 'fr' }));
  if (interactive) {
    map.addControl(new mapboxgl.NavigationControl(), 'top-left');

    map.scrollZoom.disable();
    map.scrollZoom.setWheelZoomRate(0.02);
    const scrollHideMsg = debounce(() => scroll.hideMsg(), 750);

    map.on('wheel', (event) => {
      if (event.originalEvent.ctrlKey) {
        event.originalEvent.preventDefault();
        if (!map.scrollZoom._enabled) map.scrollZoom.enable();
      } else {
        scroll.displayMsg();
        scrollHideMsg();
        if (map.scrollZoom._enabled) map.scrollZoom.disable();
      }
    });
  }

  return {
    map,
    mapbox: {
      containerId,
      pictograms,
      clusters,
      publicUrl,
      markers: {
        pins: [],
        icons: [],
      },
      routes: {},
    },
  };
}

function remove({ map }) {
  map.remove();
}

function load({ map, mapbox }, markers, lines) {
  return new Promise(function (resolve, reject) {
    map.on('load', function () {
      loadMapContent({ map, mapbox }, markers, lines)
        .then(resolve)
        .catch(reject);
    });
  });
}

async function loadMapContent({ map, mapbox }, markers, lines) {
  await loadAllImages({ map, mapbox });
  loadRoutes({ map, mapbox }, lines);
  loadIcons({ map, mapbox }, markers);
  loadPins({ map, mapbox }, markers);
}

async function loadAllImages({ map, mapbox }) {
  const images = mapbox.pictograms.getList(mapbox.publicUrl);
  await Promise.all(images.map((i) => loadImage(map, i.code, i.url)));
}

function loadImage(map, name, imageUrl) {
  return new Promise(function (resolve, reject) {
    map.loadImage(imageUrl, function (error, image) {
      if (error) reject(error);
      map.addImage(name, image);
      resolve();
    });
  });
}

function loadRoutes({ map, mapbox }, lines) {
  const routes = [];
  lines.forEach((line) => {
    const route = buildLine(line.coordinates);
    routes.push(route);
    mapbox.routes[line.id] = route;
  });

  addSource({ map, mapbox }, 'routes', routes, { cluster: false });
  map.addLayer(getDefaultRouteLayer('routes'));
}

function loadPins({ map, mapbox }, markers) {
  // Source
  const pins = markers
    .filter((marker) => marker.type.isPin)
    .map((marker) => buildMarker(marker.center, marker.type));

  mapbox.markers.pins = pins;
  addSource({ map, mapbox }, 'pins', pins);

  // Layers
  map.addLayer(
    getDefaultMarkerLayer('pins', 'pin-shadows', {
      'icon-image': 'shadow',
      'icon-anchor': 'bottom',
      'icon-offset': [10, 0],
    })
  );
  map.addLayer(
    getDefaultMarkerLayer('pins', 'pins', {
      'icon-image': buildLayerExpression('pinColor', mapbox.clusters),
      'icon-anchor': 'bottom',
    })
  );
  map.addLayer(
    getDefaultMarkerLayer('pins', 'pin-icons', {
      'icon-image': buildLayerExpression('icon', mapbox.clusters),
      'icon-anchor': 'bottom',
      'icon-offset': [0, -17],
    })
  );
}

function loadIcons({ map, mapbox }, markers) {
  const icons = markers
    .filter((marker) => !marker.type.isPin)
    .map((marker) => buildMarker(marker.center, marker.type));

  mapbox.markers.icons = icons;
  addSource({ map, mapbox }, 'icons', icons);

  map.addLayer(
    getDefaultMarkerLayer('icons', 'icons', {
      'icon-image': buildLayerExpression('icon'),
    })
  );
}

// ==================================================================================================
// Canvas
// ==================================================================================================

function isRendered({ map }) {
  return new Promise(function (resolve) {
    map.once('idle', () => {
      return resolve();
    });
  });
}

function getCanvas({ map }) {
  return map.getCanvas();
}

// ==================================================================================================
// Center Map
// ==================================================================================================

function center({ map }, center, zoom, pitch = 0, bearing = 0) {
  map.flyTo({ center: coordMgr.toMapbox(center), zoom, pitch, bearing });
}

function fitBounds({ map }, bounds) {
  const bbox = bounds.reduce(function (bbox, c) {
    return bbox.extend(coordMgr.toMapbox(c));
  }, new mapboxgl.LngLatBounds());

  map.fitBounds(bbox, { padding: 70 });
}

// ==================================================================================================
// Geocoder
// ==================================================================================================

function addGeocoder({ map }, { resultFound }) {
  const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    enableEventLogging: false,
    marker: false,
    collapsed: true,
    clearAndBlurOnEsc: true,
    minLength: 3,
  });

  geocoder.on('result', function (response) {
    const result = { center: coordMgr.toBuilder(response.result.center) };
    if (response.result.bbox) {
      result.bbox = [
        coordMgr.toBuilder([response.result.bbox[0], response.result.bbox[1]]),
        coordMgr.toBuilder([response.result.bbox[2], response.result.bbox[3]]),
      ];
    }
    resultFound(result);
  });

  map.addControl(geocoder);
}

// ==================================================================================================
// Map Interactivity
// ==================================================================================================

function editableMarkers(
  { map, mapbox },
  { clickOnMap, clickOnMarker, rightClickOnMarker }
) {
  const markerSources = Object.keys(mapbox.markers);
  const popup = popupMgr();

  map.on('click', function (e) {
    if (!popup.isOpen()) {
      const markerAtClickPosition = map
        .queryRenderedFeatures(e.point)
        .filter((feature) => markerSources.indexOf(feature.source) !== -1);

      if (markerAtClickPosition.length === 0) {
        clickOnMap(e.lngLat);
      }
    }
  });

  markerSources.forEach((source) => {
    // Hover
    map.on('mouseenter', source, function () {
      if (!popup.isOpen()) {
        map.getCanvas().style.cursor = 'pointer';
      }
    });

    map.on('mouseleave', source, function () {
      map.getCanvas().style.cursor = '';
    });

    // Click
    // TODO : build helper
    map.on('click', source, function (e) {
      if (!popup.isOpen()) {
        const coordinates = getEventCoordinates(e);
        const marker = e.features[0].properties;

        const content = marker.cluster
          ? clusterPopup.content
          : deletePopup.content(mapbox.publicUrl);
        popup.build(map, coordinates, marker.isPin, content);

        if (!marker.cluster) {
          const center = JSONParse(marker.center, {});
          deletePopup.setClickEvent(
            mapbox.containerId,
            center,
            popup,
            clickOnMarker
          );
        }
      }
    });

    map.on('contextmenu', source, function (e) {
      if (!popup.isOpen()) {
        const coordinates = getEventCoordinates(e);
        const marker = e.features[0].properties;

        const content = marker.cluster
          ? clusterPopup.content
          : mapbox.pictograms.popup.content(mapbox.publicUrl, marker.isPin);
        popup.build(map, coordinates, marker.isPin, content);

        if (!marker.cluster) {
          const center = JSONParse(marker.center, {});
          mapbox.pictograms.popup.setClickEvent(
            mapbox.containerId,
            center,
            rightClickOnMarker
          );
        }
      }
    });
  });
}

function getEventCoordinates(e) {
  const coordinates = e.features[0].geometry.coordinates.slice();

  // Ensure that if the map is zoomed out such that multiple copies of the
  // feature are visible, the popup appears over the copy being pointed to.
  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
  }

  return coordinates;
}

// ==================================================================================================
// Update Markers & Lines
// ==================================================================================================

function addMarker({ map, mapbox }, center, type) {
  const source = type.isPin ? 'pins' : 'icons';
  const newMarkers = [...mapbox.markers[source]];
  newMarkers.push(buildMarker(center, type));

  updateSource({ map, mapbox }, source, newMarkers);
}

function removeMarker({ map, mapbox }, center) {
  Object.keys(mapbox.markers).forEach((source) => {
    const markers = mapbox.markers[source];
    const newMarkers = markers.filter((marker) => {
      return !coordinatesAreEqual(marker.properties.center, center);
    });

    if (newMarkers.length !== markers.length) {
      updateSource({ map, mapbox }, source, newMarkers);
    }
  });
}

function updateSource({ map, mapbox }, source, newMarkers) {
  mapbox.markers[source] = newMarkers;
  map.getSource(source).setData({
    type: 'FeatureCollection',
    features: newMarkers,
  });
}

function drawLine({ map, mapbox }, lineId, coordinates) {
  mapbox.routes[lineId] = buildLine(coordinates);
  map.getSource('routes').setData({
    type: 'FeatureCollection',
    features: Object.keys(mapbox.routes).map((id) => mapbox.routes[id]),
  });
}

// ==================================================================================================
// Camera Position
// ==================================================================================================

function followCameraPosition({ map }, cameraPosition, setCameraPosition) {
  const container = $('.mapboxgl-control-container');
  const cameraControl = new MapboxCameraPosition(cameraPosition);
  map.addControl(cameraControl, 'top-left');

  container.on('mouseenter', cameraControl.saveSelector(), function () {
    $('.tp-table-map-print-view').removeClass('hidden');
  });
  container.on('mouseleave', cameraControl.saveSelector(), function () {
    $('.tp-table-map-print-view').addClass('hidden');
  });
  container.on('click', cameraControl.saveSelector(), function () {
    setCameraPosition({
      center: map.getCenter(),
      zoom: map.getZoom(),
      pitch: map.getPitch(),
      bearing: map.getBearing(),
    });
    cameraControl.displayDelete();
  });
  container.on('click', cameraControl.deleteSelector(), function () {
    setCameraPosition(null);
    cameraControl.hideDelete();
  });
}

// ==================================================================================================
// Clusters
// ==================================================================================================

function buildLayerExpression(property, clustersPriority = undefined) {
  const clusterExpression = clustersPriority
    ? buildClusterLayerExpression(property, clustersPriority)
    : '{void}';

  return ['case', ['has', 'cluster'], clusterExpression, ['get', property]];
}

function buildClusterLayerExpression(property, clustersPriority) {
  if (clustersPriority.length === 0) return `{${property}}`;
  if (clustersPriority.length === 1) return clustersPriority[0][property];

  const clusterProperty = [];
  clusterProperty.push('case');

  clustersPriority.forEach((val, index, arr) => {
    if (index < arr.length - 1) {
      clusterProperty.push(['>', ['get', 'is_' + val.type], 0]);
    }
    clusterProperty.push(val[property]);
  });
  return clusterProperty;
}

function buildSourceExpression(clustersPriority) {
  const clusterProperties = {};

  clustersPriority.forEach((val, index, arr) => {
    if (index < arr.length - 1) {
      clusterProperties['is_' + val.type] = [
        '+',
        ['case', ['==', ['get', 'type'], val.type], 1, 0],
      ];
    }
  });

  return clusterProperties;
}

// ==================================================================================================
// Tools
// ==================================================================================================

const coordMgr = {
  toMapbox: (c) => [c.lng, c.lat],
  toBuilder: (c) => ({ lng: c[0], lat: c[1] }),
};

function addSource({ map, mapbox }, source, features, { cluster = true } = {}) {
  const properties = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features,
    },
  };

  if (cluster) {
    map.addSource(source, {
      ...properties,
      ...{
        cluster: true,
        clusterMaxZoom: 1000,
        clusterRadius: 15,
        clusterProperties: buildSourceExpression(mapbox.clusters),
      },
    });
  } else {
    map.addSource(source, properties);
  }
}

function getDefaultRouteLayer(source, id) {
  return {
    id: id ? id : source,
    type: 'line',
    source,
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#555555',
      'line-width': 2,
    },
  };
}

function getDefaultMarkerLayer(source, id, layout = {}) {
  return {
    id: id ? id : source,
    type: 'symbol',
    source,
    layout: {
      'icon-allow-overlap': true,
      'text-allow-overlap': true,
      'icon-ignore-placement': true,
      'text-ignore-placement': true,
      ...layout,
    },
  };
}

function buildMarker(center, type) {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: coordMgr.toMapbox(center),
    },
    properties: { ...type, center },
  };
}

function buildLine(coordinates) {
  return {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: coordinates.map((c) => coordMgr.toMapbox(c)),
    },
  };
}
