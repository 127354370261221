import 'jquery';

import dialog from 'tools/dialog';
import datatables from './tools/datatables';
import { table } from 'table';

import { layout } from 'features/layout';
import { homepage } from 'features/homepage';
import { tripplanner } from 'features/tripplanner';
import { administration } from 'features/administration';
import { documentation } from 'features/documentation';
import { dolibarr } from 'features/dolibarr';

import 'custom';

dialog.initialize();
datatables.initialize();
table();

layout();
homepage();
tripplanner();
administration();
documentation();
dolibarr();
